import './App.css';
import brotherImage from './brosEdited.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={brotherImage} className="bros" alt="bros" width="500" height="600" />
        <p>
          Means boys mean business. 
        </p>
      </header>
    </div>
  );
}

export default App;
